<button
  class="inline-flex items-center flex-row gap-2 w-full"
  [ngClass]="classes()"
  (click)="confirmLogout()"
  aria-label="Logout of Bongo's Bingo"
>
  @if (icon() !== '') {
    <i class="fa {{ icon() }}"></i>
  }
  <span>{{ label() }}</span>
</button>

<app-busy-overlay [busy]="!!overlayMessage()">{{ overlayMessage() }}</app-busy-overlay>
