import { CommonModule } from '@angular/common';
import { Dialog } from '@angular/cdk/dialog';
import { ChangeDetectionStrategy, Component, computed, DestroyRef, inject, input, signal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Router, RouterModule } from '@angular/router';
import { switchMap, from, finalize } from 'rxjs';

import { AuthService } from '@features/auth';

import { ConfirmationDialogComponent } from '../confirmation-dialog';
import { BusyOverlayComponent } from '../busy-overlay';

@Component({
  selector: 'app-logout-button',
  standalone: true,
  imports: [CommonModule, RouterModule, BusyOverlayComponent],
  templateUrl: './logout-button.component.html',
  styleUrl: './logout-button.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LogoutButtonComponent {
  #dialog = inject(Dialog);
  #destroyRef = inject(DestroyRef);
  #auth = inject(AuthService);
  #router = inject(Router);

  label = input.required<string>();
  color = input<string>('outline-button secondary px-8');
  width = input<string>('');
  icon = input<string>();

  classes = computed(() => `${this.color()} ${this.width()}`);

  overlayMessage = signal<string>('');

  protected confirmLogout() {
    const dialogRef = this.#dialog.open(ConfirmationDialogComponent, {
      data: {
        title: 'Are you sure?',
        description: 'Are you sure you want to log out?',
        confirmButton: 'Log me out!',
        cancelButton: 'Cancel',
      },
    });

    dialogRef.closed.subscribe((result) => {
      if (result === true) {
        this.logout();
      }
    });
  }

  protected logout() {
    this.overlayMessage.set('Logging out...');

    this.#auth
      .logout()
      .pipe(
        takeUntilDestroyed(this.#destroyRef),
        switchMap(() => from(this.#router.navigate(['/']))),
        finalize(() => this.overlayMessage.set('')),
      )
      .subscribe();
  }
}
